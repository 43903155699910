import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`In this lesson, we'll learn a little about the connection between regular expessions and state machines.  You'll explore this topic in much more depth in LING 538.`}</p>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <p>{`After completing this lesson, you'll be able to ...`}</p>
    <ul>
      <li parentName="ul">{`translate a simple state machine into a regular expression`}</li>
      <li parentName="ul">{`translate a simple regular expression into a state machine`}</li>
      <li parentName="ul">{`explain transitions`}</li>
      <li parentName="ul">{`explain final states`}</li>
    </ul>
    <h1 {...{
      "id": "background",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#background",
        "aria-label": "background permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background`}</h1>
    <h2 {...{
      "id": "regular-languages-and-regular-expressions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#regular-languages-and-regular-expressions",
        "aria-label": "regular languages and regular expressions permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Regular languages and regular expressions`}</h2>
    <p>{`As mentioned in our introduction to regexes, regular expressions were first developed as a way of describing `}<strong parentName="p">{`regular languages`}</strong>{` in formal language theory.  `}<strong parentName="p">{`Regular languages`}</strong>{` are the simplest type of language`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` in the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Chomsky_hierarchy",
        "target": "_self",
        "rel": "nofollow"
      }}>{`Chomsky heirarchy`}</a>{`.  You'll learn all about regular languages and formal language theory in LING 539.  In this lesson, we're simply going to look at an alterative way of representing a regular expression using a `}<strong parentName="p">{`finite state machine`}</strong>{` (FSM).  `}</p>
    <h1 {...{
      "id": "fsms-and-regular-expressions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#fsms-and-regular-expressions",
        "aria-label": "fsms and regular expressions permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FSMs and regular expressions`}</h1>
    <p>{`There are several subtypes of finite state machines (FSMs)`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{` (deterministic, non-deterministic, etc.). For the purposes of this introduction, we won't explore these different types, but instead look at them generally. `}</p>
    <p>{`Finite state machines are defined by five things:`}</p>
    <ul>
      <li parentName="ul"><span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`Q`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`Q`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.8778em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`Q`}</span></span></span></span></span>{`: a finite set of states (ex. `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`q`}</mi><mn parentName="mrow">{`1`}</mn><mo parentName="mrow" {...{
                      "separator": "true"
                    }}>{`,`}</mo><mi parentName="mrow">{`q`}</mi><mn parentName="mrow">{`2`}</mn></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`{q1, q2}`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.8389em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03588em"
                    }
                  }}>{`q`}</span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`1`}</span><span parentName="span" {...{
                    "className": "mpunct"
                  }}>{`,`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.1667em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03588em"
                    }
                  }}>{`q`}</span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`2`}</span></span></span></span></span></span>{`)`}</li>
      <li parentName="ul"><span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`Σ`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`\\Sigma`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}>{`Σ`}</span></span></span></span></span><sup parentName="li" {...{
          "id": "fnref-3"
        }}><a parentName="sup" {...{
            "href": "#fn-3",
            "className": "footnote-ref"
          }}>{`3`}</a></sup>{`: an alphabet which is comprised of a finite set of input symbols `}</li>
      <li parentName="ul"><span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`δ`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`\\delta`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03785em"
                  }
                }}>{`δ`}</span></span></span></span></span><sup parentName="li" {...{
          "id": "fnref-4"
        }}><a parentName="sup" {...{
            "href": "#fn-4",
            "className": "footnote-ref"
          }}>{`4`}</a></sup>{`: a transition function that tells how to move from one state to another by means of reading a symbol from our alphabet `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`Σ`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`\\Sigma`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}>{`Σ`}</span></span></span></span></span>
        <ul parentName="li">
          <li parentName="ul"><span parentName="li" {...{
              "className": "math math-inline"
            }}><span parentName="span" {...{
                "className": "katex"
              }}><span parentName="span" {...{
                  "className": "katex-mathml"
                }}><math parentName="span" {...{
                    "xmlns": "http://www.w3.org/1998/Math/MathML"
                  }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`δ`}</mi><mo parentName="mrow">{`:`}</mo><mi parentName="mrow">{`Q`}</mi><mo parentName="mrow">{`×`}</mo><mi parentName="mrow" {...{
                          "mathvariant": "normal"
                        }}>{`Σ`}</mi><mo parentName="mrow">{`→`}</mo><mi parentName="mrow">{`Q`}</mi></mrow><annotation parentName="semantics" {...{
                        "encoding": "application/x-tex"
                      }}>{`\\delta : Q \\times \\Sigma \\rightarrow Q`}</annotation></semantics></math></span><span parentName="span" {...{
                  "className": "katex-html",
                  "aria-hidden": "true"
                }}><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.6944em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord mathnormal",
                      "style": {
                        "marginRight": "0.03785em"
                      }
                    }}>{`δ`}</span><span parentName="span" {...{
                      "className": "mspace",
                      "style": {
                        "marginRight": "0.2778em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mrel"
                    }}>{`:`}</span><span parentName="span" {...{
                      "className": "mspace",
                      "style": {
                        "marginRight": "0.2778em"
                      }
                    }}></span></span><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.8778em",
                        "verticalAlign": "-0.1944em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord mathnormal"
                    }}>{`Q`}</span><span parentName="span" {...{
                      "className": "mspace",
                      "style": {
                        "marginRight": "0.2222em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mbin"
                    }}>{`×`}</span><span parentName="span" {...{
                      "className": "mspace",
                      "style": {
                        "marginRight": "0.2222em"
                      }
                    }}></span></span><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.6833em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord"
                    }}>{`Σ`}</span><span parentName="span" {...{
                      "className": "mspace",
                      "style": {
                        "marginRight": "0.2778em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mrel"
                    }}>{`→`}</span><span parentName="span" {...{
                      "className": "mspace",
                      "style": {
                        "marginRight": "0.2778em"
                      }
                    }}></span></span><span parentName="span" {...{
                    "className": "base"
                  }}><span parentName="span" {...{
                      "className": "strut",
                      "style": {
                        "height": "0.8778em",
                        "verticalAlign": "-0.1944em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "mord mathnormal"
                    }}>{`Q`}</span></span></span></span></span></li>
        </ul>
      </li>
      <li parentName="ul"><span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`0`}</mn></msub></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`q_{0}`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.625em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03588em"
                    }
                  }}>{`q`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.3011em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.55em",
                              "marginLeft": "-0.0359em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`0`}</span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`: our start state `}</li>
      <li parentName="ul"><span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`F`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`F`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.13889em"
                  }
                }}>{`F`}</span></span></span></span></span>{`: a set of accept aka `}<strong parentName="li">{`terminal`}</strong>{` aka `}<strong parentName="li">{`f`}</strong>{`inal states (these are a subset of `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`Q`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`Q`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.8778em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`Q`}</span></span></span></span></span>{`)`}</li>
    </ul>
    <p><undefined parentName="p"><span {...{
          "role": "img",
          "aria-label": "exclamation mark"
        }}>{`❗`}</span>{` `}</undefined><em parentName="p">{`Every finite state machine can be represented using an equivalent regular expression.`}</em>{` `}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Equivalent`}</em>{` here means that both the regular expression and the finite state machine accept exactly the same set of strings.`}</li>
    </ul>
    <p>{`Like a regular expression, we can think of a state machine as a validator/acceptor/recognizer of strings.  Imagine we wanted to match the string `}<strong parentName="p">{`abc`}</strong>{`.  We can represent a language containing `}<em parentName="p">{`only`}</em>{` the string `}<strong parentName="p">{`abc`}</strong>{` with a regular expression:`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`abc`}</code></p>
    <p>{`Alternatively, we can use a state machine:`}</p>
    <svg {...{
      "width": "287pt",
      "height": "52pt",
      "viewBox": "0.00 0.00 287.43 52.00",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 48)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-48 283.4292,-48 283.4292,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-17.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "96.2146",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.1406,-22C45.6174,-22 57.4264,-22 68.0602,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "68.1724,-25.5001 78.1724,-22 68.1723,-18.5001 68.1724,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "57.1073",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`q2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "175.2146",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "175.2146",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q2`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M114.5374,-22C124.1093,-22 136.0369,-22 146.7775,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "146.9912,-25.5001 156.9912,-22 146.9912,-18.5001 146.9912,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* q3 */
        }
        <g parentName="g" {...{
          "id": "node4",
          "className": "node"
        }}>
          <title parentName="g">{`q3`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "257.4292",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "257.4292",
            "cy": "-22",
            "rx": "22",
            "ry": "22"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "257.4292",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q3`}</text>
        </g>
        {
          /* q2&#45;&gt;q3 */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`q2->q3`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M193.4607,-22C202.6855,-22 214.1625,-22 224.838,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "225.1334,-25.5001 235.1333,-22 225.1333,-18.5001 225.1334,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "214.3219",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`c`}</text>
        </g>
      </g>
    </svg>
    <p>{`The regular expression `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`abc`}</code>{` is equivalent to the state machine depicted above.  Both the regular expression and the state machine accept exactly the same set of strings:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`L`}</mi><mo parentName="mrow">{`=`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`{`}</mo><mtext parentName="mrow">{`abc`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`}`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`L = \\{\\text{abc}\\}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`L`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`abc`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`}`}</span></span></span></span></span></p>
    <p>{`The circles in our FSM represent its states and the lines between them represent valid transitions.  We start in the state marked with an `}<strong parentName="p">{`S`}</strong>{`:`}</p>
    <svg {...{
      "width": "44pt",
      "height": "44pt",
      "viewBox": "0.00 0.00 44.00 44.00",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 40)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-40 40,-40 40,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-18",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-13.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
      </g>
    </svg>
    <p>{`In our little language, the only character that can start a string is `}<strong parentName="p">{`a`}</strong>{`:  `}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`L`}</mi><mo parentName="mrow">{`=`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`{`}</mo><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`a`}</mi><mtext parentName="mrow">{`bc`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`}`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`L = \\{\\mathbf{a}\\text{bc}\\}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`L`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`bc`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`}`}</span></span></span></span></span></p>
    <p>{`This is represented by the following transition:`}</p>
    <svg {...{
      "width": "122pt",
      "height": "44pt",
      "viewBox": "0.00 0.00 122.21 44.00",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 40)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-40 118.2146,-40 118.2146,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-18",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-13.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "96.2146",
            "cy": "-18",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-14.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.1406,-18C45.6174,-18 57.4264,-18 68.0602,-18"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "68.1724,-21.5001 78.1724,-18 68.1723,-14.5001 68.1724,-21.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "57.1073",
            "y": "-22.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
      </g>
    </svg>
    <p>{`Reading or observing an `}<strong parentName="p">{`a`}</strong>{` takes us from our start state, `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`S`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`S`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.05764em"
                }
              }}>{`S`}</span></span></span></span></span>{`, to a new state called `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`1`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_1`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`1`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`. Next, we read a `}<strong parentName="p">{`b`}</strong>{`.  This takes us to a new state, `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_2`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`2`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`:`}</p>
    <svg {...{
      "width": "201pt",
      "height": "44pt",
      "viewBox": "0.00 0.00 201.21 44.00",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 40)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-40 197.2146,-40 197.2146,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-18",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-13.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "96.2146",
            "cy": "-18",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-14.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.1406,-18C45.6174,-18 57.4264,-18 68.0602,-18"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "68.1724,-21.5001 78.1724,-18 68.1723,-14.5001 68.1724,-21.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "57.1073",
            "y": "-22.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`q2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "175.2146",
            "cy": "-18",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "175.2146",
            "y": "-14.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q2`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M114.5374,-18C124.1093,-18 136.0369,-18 146.7775,-18"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "146.9912,-21.5001 156.9912,-18 146.9912,-14.5001 146.9912,-21.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-22.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
      </g>
    </svg>
    <p>{`From `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_2`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`2`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`, there is only one possible transition: `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`2`}</mn></msub><mo parentName="mrow">{`→`}</mo><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`3`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_2 \\rightarrow q_3`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`2`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`3`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` by means of reading a `}<strong parentName="p">{`c`}</strong>{`:`}</p>
    <svg {...{
      "width": "287pt",
      "height": "52pt",
      "viewBox": "0.00 0.00 287.43 52.00",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 48)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-48 283.4292,-48 283.4292,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-17.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "96.2146",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.1406,-22C45.6174,-22 57.4264,-22 68.0602,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "68.1724,-25.5001 78.1724,-22 68.1723,-18.5001 68.1724,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "57.1073",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`q2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "175.2146",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "175.2146",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q2`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M114.5374,-22C124.1093,-22 136.0369,-22 146.7775,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "146.9912,-25.5001 156.9912,-22 146.9912,-18.5001 146.9912,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* q3 */
        }
        <g parentName="g" {...{
          "id": "node4",
          "className": "node"
        }}>
          <title parentName="g">{`q3`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "257.4292",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "257.4292",
            "cy": "-22",
            "rx": "22",
            "ry": "22"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "257.4292",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q3`}</text>
        </g>
        {
          /* q2&#45;&gt;q3 */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`q2->q3`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M193.4607,-22C202.6855,-22 214.1625,-22 224.838,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "225.1334,-25.5001 235.1333,-22 225.1333,-18.5001 225.1334,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "214.3219",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`c`}</text>
        </g>
      </g>
    </svg>
    <p>{`Notice anything different about `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`3`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_3`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`3`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` in the example above?  `}</p>
    <svg {...{
      "width": "52pt",
      "height": "52pt",
      "viewBox": "0.00 0.00 52.00 52.00",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 48)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-48 48,-48 48,4 -4,4"
        }}></polygon>
        {
          /* q3 */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`q3`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "22",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "22",
            "cy": "-22",
            "rx": "22",
            "ry": "22"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "22",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q3`}</text>
        </g>
      </g>
    </svg>
    <p>{`The concentric circle indicates that this is a `}<strong parentName="p">{`terminal`}</strong><sup parentName="p" {...{
        "id": "fnref-5"
      }}><a parentName="sup" {...{
          "href": "#fn-5",
          "className": "footnote-ref"
        }}>{`5`}</a></sup>{` state.  `}</p>
    <p>{`If we end up in this state and we've read off all of the characters for the string we're testing, that string is `}<em parentName="p">{`accepted`}</em>{` by this state machine and is therefore part of the language represented by the machine.`}</p>
    <p>{`Let's extend our language so that it includes the following strings:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`abc
aabc`}</code></pre></div>
    <p>{`Alternatively, we can write it in the following way:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`L`}</mi><mo parentName="mrow">{`=`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`{`}</mo><mtext parentName="mrow">{`abc`}</mtext><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mtext parentName="mrow">{`aabc`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`}`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`L = \\{\\text{abc}, \\text{aabc}\\}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`L`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`abc`}</span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`aabc`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`}`}</span></span></span></span></span></p>
    <p>{`Here is a deterministic finite state machine that defines our language, `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`L`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`L`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`L`}</span></span></span></span></span>{`:`}</p>
    <svg {...{
      "width": "366pt",
      "height": "85pt",
      "viewBox": "0.00 0.00 365.64 85.00",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 81)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-81 361.6438,-81 361.6438,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-17.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "96.2146",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.1406,-22C45.6174,-22 57.4264,-22 68.0602,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "68.1724,-25.5001 78.1724,-22 68.1723,-18.5001 68.1724,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "57.1073",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`q2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "174.4292",
            "cy": "-59",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "174.4292",
            "y": "-55.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q2`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M112.8019,-29.8467C123.2417,-34.7854 136.9296,-41.2605 148.753,-46.8537"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "147.5977,-50.179 158.134,-51.2914 150.5911,-43.8513 147.5977,-50.179"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.3219",
            "y": "-45.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q3 */
        }
        <g parentName="g" {...{
          "id": "node4",
          "className": "node"
        }}>
          <title parentName="g">{`q3`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "253.4292",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "253.4292",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q3`}</text>
        </g>
        {
          /* q1&#45;&gt;q3 */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q3`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M114.0866,-19.4483C126.0428,-17.8729 142.1441,-16.004 156.4292,-15.2 172.4039,-14.3008 176.4538,-14.3123 192.4292,-15.2 203.2474,-15.8011 215.0951,-16.9975 225.4478,-18.2214"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "225.2339,-21.7217 235.5895,-19.483 226.0981,-14.7752 225.2339,-21.7217"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "174.4292",
            "y": "-20.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* q2&#45;&gt;q3 */
        }
        <g parentName="g" {...{
          "id": "edge4",
          "className": "edge"
        }}>
          <title parentName="g">{`q2->q3`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M190.7981,-51.3336C201.4964,-46.3229 215.6902,-39.6752 227.8604,-33.9753"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "229.5099,-37.0677 237.0813,-29.6566 226.5408,-30.7285 229.5099,-37.0677"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "213.9292",
            "y": "-45.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* q4 */
        }
        <g parentName="g" {...{
          "id": "node5",
          "className": "node"
        }}>
          <title parentName="g">{`q4`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "335.6438",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "335.6438",
            "cy": "-22",
            "rx": "22",
            "ry": "22"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "335.6438",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q4`}</text>
        </g>
        {
          /* q3&#45;&gt;q4 */
        }
        <g parentName="g" {...{
          "id": "edge5",
          "className": "edge"
        }}>
          <title parentName="g">{`q3->q4`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M271.6753,-22C280.9001,-22 292.3771,-22 303.0526,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "303.348,-25.5001 313.3479,-22 303.3479,-18.5001 303.348,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "292.5365",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`c`}</text>
        </g>
      </g>
    </svg>
    <p>{`We still have a single terminal state, `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`4`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_4`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`4`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`, but now there are two possible paths to `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`4`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_4`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`4`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`:`}</p>
    <ul>
      <li parentName="ul"><span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`S`}</mi><mo parentName="mrow">{`→`}</mo><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow">{`→`}</mo><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`2`}</mn></msub><mo parentName="mrow">{`→`}</mo><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`3`}</mn></msub><mo parentName="mrow">{`→`}</mo><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`4`}</mn></msub></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`S \\rightarrow q_1 \\rightarrow q_2 \\rightarrow q_3 \\rightarrow q_4`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.05764em"
                  }
                }}>{`S`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mrel"
                }}>{`→`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span></span><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.625em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03588em"
                    }
                  }}>{`q`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.3011em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.55em",
                              "marginLeft": "-0.0359em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mrel"
                }}>{`→`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span></span><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.625em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03588em"
                    }
                  }}>{`q`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.3011em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.55em",
                              "marginLeft": "-0.0359em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mrel"
                }}>{`→`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span></span><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.625em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03588em"
                    }
                  }}>{`q`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.3011em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.55em",
                              "marginLeft": "-0.0359em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`3`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mrel"
                }}>{`→`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span></span><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.625em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03588em"
                    }
                  }}>{`q`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.3011em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.55em",
                              "marginLeft": "-0.0359em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`4`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`  `}</li>
      <li parentName="ul"><span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`S`}</mi><mo parentName="mrow">{`→`}</mo><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow">{`→`}</mo><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`3`}</mn></msub><mo parentName="mrow">{`→`}</mo><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`4`}</mn></msub></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`S \\rightarrow q_1 \\rightarrow q_3 \\rightarrow q_4`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.05764em"
                  }
                }}>{`S`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mrel"
                }}>{`→`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span></span><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.625em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03588em"
                    }
                  }}>{`q`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.3011em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.55em",
                              "marginLeft": "-0.0359em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mrel"
                }}>{`→`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span></span><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.625em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03588em"
                    }
                  }}>{`q`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.3011em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.55em",
                              "marginLeft": "-0.0359em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`3`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mrel"
                }}>{`→`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2778em"
                  }
                }}></span></span><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.625em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03588em"
                    }
                  }}>{`q`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.3011em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.55em",
                              "marginLeft": "-0.0359em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`4`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></li>
    </ul>
    <p>{`Ok.  Let's look at one last example.  What if our language allows an `}<em parentName="p">{`unlimited`}</em>{` number of repetitions of `}<em parentName="p">{`a`}</em>{`?`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`L`}</mi><mo parentName="mrow">{`=`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`{`}</mo><mtext parentName="mrow">{`abc`}</mtext><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mtext parentName="mrow">{`aabc`}</mtext><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mtext parentName="mrow">{`aaabc`}</mtext><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mtext parentName="mrow">{`aaaaabc`}</mtext><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mo parentName="mrow">{`…`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`}`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`L = \\{\\text{abc}, \\text{aabc}, \\text{aaabc}, \\text{aaaaabc}, \\ldots \\}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`L`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`abc`}</span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`aabc`}</span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`aaabc`}</span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`aaaaabc`}</span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}>{`…`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`}`}</span></span></span></span></span></p>
    <p>{`Can we represent such a string of infinite length with a finite number of states? Absa-loop-ley!`}</p>
    <svg {...{
      "width": "287pt",
      "height": "83pt",
      "viewBox": "0.00 0.00 287.43 82.80",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 78.8)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-78.8 283.4292,-78.8 283.4292,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-17.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "96.2146",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.1406,-22C45.6174,-22 57.4264,-22 68.0602,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "68.1724,-25.5001 78.1724,-22 68.1723,-18.5001 68.1724,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "57.1073",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q1&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M89.481,-39.0373C88.1068,-48.8579 90.3513,-58 96.2146,-58 99.8792,-58 102.1301,-54.4289 102.9675,-49.3529"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "106.467,-49.0307 102.9482,-39.0373 99.467,-49.0439 106.467,-49.0307"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-62.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`q2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "175.2146",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "175.2146",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q2`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M114.5374,-22C124.1093,-22 136.0369,-22 146.7775,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "146.9912,-25.5001 156.9912,-22 146.9912,-18.5001 146.9912,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* q3 */
        }
        <g parentName="g" {...{
          "id": "node4",
          "className": "node"
        }}>
          <title parentName="g">{`q3`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "257.4292",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "257.4292",
            "cy": "-22",
            "rx": "22",
            "ry": "22"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "257.4292",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q3`}</text>
        </g>
        {
          /* q2&#45;&gt;q3 */
        }
        <g parentName="g" {...{
          "id": "edge4",
          "className": "edge"
        }}>
          <title parentName="g">{`q2->q3`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M193.4607,-22C202.6855,-22 214.1625,-22 224.838,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "225.1334,-25.5001 235.1333,-22 225.1333,-18.5001 225.1334,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "214.3219",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`c`}</text>
        </g>
      </g>
    </svg>
    <p>{`Using a loop mechanism, every subsequent `}<strong parentName="p">{`a`}</strong>{` that we read will lead us right back to `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`1`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_1`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`1`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`:`}</p>
    <svg {...{
      "width": "122pt",
      "height": "79pt",
      "viewBox": "0.00 0.00 122.21 78.80",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 74.8)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-74.8 118.2146,-74.8 118.2146,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-18",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-13.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "96.2146",
            "cy": "-18",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-14.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.1406,-18C45.6174,-18 57.4264,-18 68.0602,-18"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "68.1724,-21.5001 78.1724,-18 68.1723,-14.5001 68.1724,-21.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "57.1073",
            "y": "-22.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q1&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M89.481,-35.0373C88.1068,-44.8579 90.3513,-54 96.2146,-54 99.8792,-54 102.1301,-50.4289 102.9675,-45.3529"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "106.467,-45.0307 102.9482,-35.0373 99.467,-45.0439 106.467,-45.0307"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-58.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
      </g>
    </svg>
    <p>{`The only way to leave `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`1`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_1`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`1`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` for another state is to read off a `}<strong parentName="p">{`b`}</strong>{`:`}</p>
    <svg {...{
      "width": "201pt",
      "height": "79pt",
      "viewBox": "0.00 0.00 201.21 78.80",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 74.8)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-74.8 197.2146,-74.8 197.2146,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-18",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-13.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "96.2146",
            "cy": "-18",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-14.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.1406,-18C45.6174,-18 57.4264,-18 68.0602,-18"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "68.1724,-21.5001 78.1724,-18 68.1723,-14.5001 68.1724,-21.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "57.1073",
            "y": "-22.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q1&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M89.481,-35.0373C88.1068,-44.8579 90.3513,-54 96.2146,-54 99.8792,-54 102.1301,-50.4289 102.9675,-45.3529"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "106.467,-45.0307 102.9482,-35.0373 99.467,-45.0439 106.467,-45.0307"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-58.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`q2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "175.2146",
            "cy": "-18",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "175.2146",
            "y": "-14.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q2`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M114.5374,-18C124.1093,-18 136.0369,-18 146.7775,-18"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "146.9912,-21.5001 156.9912,-18 146.9912,-14.5001 146.9912,-21.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-22.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
      </g>
    </svg>
    <p>{`The strings accepted by our language all end with a `}<strong parentName="p">{`c`}</strong>{`.  We need one last state:`}</p>
    <svg {...{
      "width": "287pt",
      "height": "83pt",
      "viewBox": "0.00 0.00 287.43 82.80",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 78.8)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-78.8 283.4292,-78.8 283.4292,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-17.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "96.2146",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.1406,-22C45.6174,-22 57.4264,-22 68.0602,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "68.1724,-25.5001 78.1724,-22 68.1723,-18.5001 68.1724,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "57.1073",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q1&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M89.481,-39.0373C88.1068,-48.8579 90.3513,-58 96.2146,-58 99.8792,-58 102.1301,-54.4289 102.9675,-49.3529"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "106.467,-49.0307 102.9482,-39.0373 99.467,-49.0439 106.467,-49.0307"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-62.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`q2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "175.2146",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "175.2146",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q2`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M114.5374,-22C124.1093,-22 136.0369,-22 146.7775,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "146.9912,-25.5001 156.9912,-22 146.9912,-18.5001 146.9912,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* q3 */
        }
        <g parentName="g" {...{
          "id": "node4",
          "className": "node"
        }}>
          <title parentName="g">{`q3`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "257.4292",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "257.4292",
            "cy": "-22",
            "rx": "22",
            "ry": "22"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "257.4292",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q3`}</text>
        </g>
        {
          /* q2&#45;&gt;q3 */
        }
        <g parentName="g" {...{
          "id": "edge4",
          "className": "edge"
        }}>
          <title parentName="g">{`q2->q3`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M193.4607,-22C202.6855,-22 214.1625,-22 224.838,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "225.1334,-25.5001 235.1333,-22 225.1333,-18.5001 225.1334,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "214.3219",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`c`}</text>
        </g>
      </g>
    </svg>
    <p>{`This state machine accepts the following language:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`L`}</mi><mo parentName="mrow">{`=`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`{`}</mo><mtext parentName="mrow">{`abc`}</mtext><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mtext parentName="mrow">{`aabc`}</mtext><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mtext parentName="mrow">{`aaabc`}</mtext><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mtext parentName="mrow">{`aaaaabc`}</mtext><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mo parentName="mrow">{`…`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`}`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`L = \\{\\text{abc}, \\text{aabc}, \\text{aaabc}, \\text{aaaaabc}, \\ldots \\}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`L`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`abc`}</span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`aabc`}</span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`aaabc`}</span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`aaaaabc`}</span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}>{`…`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`}`}</span></span></span></span></span></p>
    <p>{`Try running a few example strings through to test that this is indeed the case.`}</p>
    <h1 {...{
      "id": "conversion",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#conversion",
        "aria-label": "conversion permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Conversion`}</h1>
    <h2 {...{
      "id": "regular-expression-rightarrow-fsm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#regular-expression-rightarrow-fsm",
        "aria-label": "regular expression rightarrow fsm permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Regular expression `}<span parentName="h2" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow">{`→`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\rightarrow`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span></span></span></span></span>{` FSM`}</h2>
    <p>{`One intuitive way of creating a state machine from a regular expression is to enumerate the strings matched by the regular expression.  For example, take the following expression:`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`a[bc]d`}</code></p>
    <p>{`Let's list the strings it will match:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`abd
acd`}</code></pre></div>
    <p>{`To create an equivalent finite state machine, we need to ensure that our FSM accepts exactly the same strings (no additions allowed!):`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`L`}</mi><mo parentName="mrow">{`=`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`{`}</mo><mtext parentName="mrow">{`abd`}</mtext><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mtext parentName="mrow">{`acd`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`}`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`L = \\{\\text{abd}, \\text{acd}\\}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`L`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`abd`}</span></span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`acd`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`}`}</span></span></span></span></span></p>
    <p>{`In this case, all strings accepted by our regular expression start with `}<strong parentName="p">{`a`}</strong>{`.  That means our start state in our state machine will have exactly one outgoing transition with the label `}<strong parentName="p">{`a`}</strong>{`:`}</p>
    <svg {...{
      "width": "122pt",
      "height": "44pt",
      "viewBox": "0.00 0.00 122.21 44.00",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 40)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-40 118.2146,-40 118.2146,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-18",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-13.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "96.2146",
            "cy": "-18",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-14.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.1406,-18C45.6174,-18 57.4264,-18 68.0602,-18"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "68.1724,-21.5001 78.1724,-18 68.1723,-14.5001 68.1724,-21.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "57.1073",
            "y": "-22.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
      </g>
    </svg>
    <p>{`From there we can move to the next state by reading either a `}<strong parentName="p">{`b`}</strong>{` or a `}<strong parentName="p">{`c`}</strong>{`:`}</p>
    <svg {...{
      "width": "201pt",
      "height": "48pt",
      "viewBox": "0.00 0.00 201.21 48.20",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 44.196)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-44.196 197.2146,-44.196 197.2146,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-17.996",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "96.2146",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-18.596",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.1406,-22.196C45.6174,-22.196 57.4264,-22.196 68.0602,-22.196"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "68.1724,-25.6961 78.1724,-22.196 68.1723,-18.6961 68.1724,-25.6961"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "57.1073",
            "y": "-26.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`q2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "175.2146",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "175.2146",
            "y": "-18.596",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q2`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M114.5374,-22.196C124.1093,-22.196 136.0369,-22.196 146.7775,-22.196"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "146.9912,-25.696 156.9912,-22.196 146.9912,-18.696 146.9912,-25.696"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-26.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M109.6432,-10.0107C117.8759,-4.0114 128.8018,1.396 139.2146,-1.396 143.4501,-2.5316 147.7026,-4.2995 151.7257,-6.3358"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "150.1623,-9.4737 160.5801,-11.4026 153.639,-3.3981 150.1623,-9.4737"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-5.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`c`}</text>
        </g>
      </g>
    </svg>
    <p>{`From this next position, `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_{2}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`, we can only read a `}<strong parentName="p">{`d`}</strong>{`.  Let's add another state:`}</p>
    <svg {...{
      "width": "280pt",
      "height": "48pt",
      "viewBox": "0.00 0.00 280.21 48.20",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 44.196)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-44.196 276.2146,-44.196 276.2146,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-17.996",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "96.2146",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-18.596",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.1406,-22.196C45.6174,-22.196 57.4264,-22.196 68.0602,-22.196"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "68.1724,-25.6961 78.1724,-22.196 68.1723,-18.6961 68.1724,-25.6961"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "57.1073",
            "y": "-26.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`q2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "175.2146",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "175.2146",
            "y": "-18.596",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q2`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M114.5374,-22.196C124.1093,-22.196 136.0369,-22.196 146.7775,-22.196"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "146.9912,-25.696 156.9912,-22.196 146.9912,-18.696 146.9912,-25.696"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-26.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M109.6432,-10.0107C117.8759,-4.0114 128.8018,1.396 139.2146,-1.396 143.4501,-2.5316 147.7026,-4.2995 151.7257,-6.3358"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "150.1623,-9.4737 160.5801,-11.4026 153.639,-3.3981 150.1623,-9.4737"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-5.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`c`}</text>
        </g>
        {
          /* q3 */
        }
        <g parentName="g" {...{
          "id": "node4",
          "className": "node"
        }}>
          <title parentName="g">{`q3`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "254.2146",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "254.2146",
            "y": "-18.596",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q3`}</text>
        </g>
        {
          /* q2&#45;&gt;q3 */
        }
        <g parentName="g" {...{
          "id": "edge4",
          "className": "edge"
        }}>
          <title parentName="g">{`q2->q3`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M193.5374,-22.196C203.1093,-22.196 215.0369,-22.196 225.7775,-22.196"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "225.9912,-25.696 235.9912,-22.196 225.9912,-18.696 225.9912,-25.696"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "214.7146",
            "y": "-26.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`d`}</text>
        </g>
      </g>
    </svg>
    <p>{`The strings that comprise our language all end with `}<strong parentName="p">{`d`}</strong>{`.  To accept such strings, `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`3`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_3`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`3`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` must be a terminal state:`}</p>
    <svg {...{
      "width": "288pt",
      "height": "52pt",
      "viewBox": "0.00 0.00 288.21 52.20",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 48.196)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-48.196 284.2146,-48.196 284.2146,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-17.996",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "96.2146",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-18.596",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.1406,-22.196C45.6174,-22.196 57.4264,-22.196 68.0602,-22.196"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "68.1724,-25.6961 78.1724,-22.196 68.1723,-18.6961 68.1724,-25.6961"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "57.1073",
            "y": "-26.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`q2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "175.2146",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "175.2146",
            "y": "-18.596",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q2`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M114.5374,-22.196C124.1093,-22.196 136.0369,-22.196 146.7775,-22.196"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "146.9912,-25.696 156.9912,-22.196 146.9912,-18.696 146.9912,-25.696"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-26.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M109.6432,-10.0107C117.8759,-4.0114 128.8018,1.396 139.2146,-1.396 143.4501,-2.5316 147.7026,-4.2995 151.7257,-6.3358"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "150.1623,-9.4737 160.5801,-11.4026 153.639,-3.3981 150.1623,-9.4737"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-5.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`c`}</text>
        </g>
        {
          /* q3 */
        }
        <g parentName="g" {...{
          "id": "node4",
          "className": "node"
        }}>
          <title parentName="g">{`q3`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "258.2146",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "258.2146",
            "cy": "-22.196",
            "rx": "22",
            "ry": "22"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "258.2146",
            "y": "-18.596",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q3`}</text>
        </g>
        {
          /* q2&#45;&gt;q3 */
        }
        <g parentName="g" {...{
          "id": "edge4",
          "className": "edge"
        }}>
          <title parentName="g">{`q2->q3`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M193.2244,-22.196C202.7629,-22.196 214.77,-22.196 225.8602,-22.196"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "226.1016,-25.696 236.1016,-22.196 226.1015,-18.696 226.1016,-25.696"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "214.7146",
            "y": "-26.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`d`}</text>
        </g>
      </g>
    </svg>
    <p>{`If we wanted to write a regular expression corresponding to this state machine, we can create one incrementally by reading off the transitions:`}</p>
    <svg {...{
      "width": "122pt",
      "height": "44pt",
      "viewBox": "0.00 0.00 122.21 44.00",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 40)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-40 118.2146,-40 118.2146,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-18",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-13.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "96.2146",
            "cy": "-18",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-14.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.1406,-18C45.6174,-18 57.4264,-18 68.0602,-18"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "68.1724,-21.5001 78.1724,-18 68.1723,-14.5001 68.1724,-21.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "57.1073",
            "y": "-22.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
      </g>
    </svg>
    <p>{`Based on that transition, our regular expression so far is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`a`}</code>{`.`}</p>
    <p>{`From `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`1`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_1`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`1`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`, two transitions are possible:`}</p>
    <svg {...{
      "width": "201pt",
      "height": "48pt",
      "viewBox": "0.00 0.00 201.21 48.20",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 44.196)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-44.196 197.2146,-44.196 197.2146,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-17.996",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "96.2146",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-18.596",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.1406,-22.196C45.6174,-22.196 57.4264,-22.196 68.0602,-22.196"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "68.1724,-25.6961 78.1724,-22.196 68.1723,-18.6961 68.1724,-25.6961"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "57.1073",
            "y": "-26.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`q2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "175.2146",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "175.2146",
            "y": "-18.596",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q2`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M114.5374,-22.196C124.1093,-22.196 136.0369,-22.196 146.7775,-22.196"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "146.9912,-25.696 156.9912,-22.196 146.9912,-18.696 146.9912,-25.696"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-26.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M109.6432,-10.0107C117.8759,-4.0114 128.8018,1.396 139.2146,-1.396 143.4501,-2.5316 147.7026,-4.2995 151.7257,-6.3358"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "150.1623,-9.4737 160.5801,-11.4026 153.639,-3.3981 150.1623,-9.4737"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-5.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`c`}</text>
        </g>
      </g>
    </svg>
    <p>{`Our regular expression is now `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`a(b|c)`}</code>{`.`}</p>
    <p>{`Our only remaining transition is `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`2`}</mn></msub><mo parentName="mrow">{`→`}</mo><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`3`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_{2} \\rightarrow q_{3}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`3`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` which is traversed by reading a `}<strong parentName="p">{`d`}</strong>{`:`}</p>
    <svg {...{
      "width": "280pt",
      "height": "48pt",
      "viewBox": "0.00 0.00 280.21 48.20",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 44.196)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-44.196 276.2146,-44.196 276.2146,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-17.996",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "96.2146",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-18.596",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.1406,-22.196C45.6174,-22.196 57.4264,-22.196 68.0602,-22.196"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "68.1724,-25.6961 78.1724,-22.196 68.1723,-18.6961 68.1724,-25.6961"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "57.1073",
            "y": "-26.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`q2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "175.2146",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "175.2146",
            "y": "-18.596",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q2`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M114.5374,-22.196C124.1093,-22.196 136.0369,-22.196 146.7775,-22.196"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "146.9912,-25.696 156.9912,-22.196 146.9912,-18.696 146.9912,-25.696"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-26.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M109.6432,-10.0107C117.8759,-4.0114 128.8018,1.396 139.2146,-1.396 143.4501,-2.5316 147.7026,-4.2995 151.7257,-6.3358"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "150.1623,-9.4737 160.5801,-11.4026 153.639,-3.3981 150.1623,-9.4737"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-5.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`c`}</text>
        </g>
        {
          /* q3 */
        }
        <g parentName="g" {...{
          "id": "node4",
          "className": "node"
        }}>
          <title parentName="g">{`q3`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "254.2146",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "254.2146",
            "y": "-18.596",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q3`}</text>
        </g>
        {
          /* q2&#45;&gt;q3 */
        }
        <g parentName="g" {...{
          "id": "edge4",
          "className": "edge"
        }}>
          <title parentName="g">{`q2->q3`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M193.5374,-22.196C203.1093,-22.196 215.0369,-22.196 225.7775,-22.196"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "225.9912,-25.696 235.9912,-22.196 225.9912,-18.696 225.9912,-25.696"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "214.7146",
            "y": "-26.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`d`}</text>
        </g>
      </g>
    </svg>
    <p>{`Our final expression is thus `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`a(b|c)d`}</code>{`.`}</p>
    <h2 {...{
      "id": "finite-state-machine-rightarrow-regular-expression",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#finite-state-machine-rightarrow-regular-expression",
        "aria-label": "finite state machine rightarrow regular expression permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Finite state machine `}<span parentName="h2" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow">{`→`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\rightarrow`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.3669em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span></span></span></span></span>{` regular expression`}</h2>
    <p>{`Every finite state machine has an equivalent regular expression, but how do we generate that regular expression?`}</p>
    <p>{`Let's examine one algorithm for doing so...`}</p>
    <p>{`We'll generate a regular expression for each state and then concatenate the expressions.  `}</p>
    <p>{`Let's look at an example:`}</p>
    <svg {...{
      "width": "280pt",
      "height": "48pt",
      "viewBox": "0.00 0.00 280.21 48.20",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 44.196)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-44.196 276.2146,-44.196 276.2146,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-17.996",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "96.2146",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "96.2146",
            "y": "-18.596",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.1406,-22.196C45.6174,-22.196 57.4264,-22.196 68.0602,-22.196"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "68.1724,-25.6961 78.1724,-22.196 68.1723,-18.6961 68.1724,-25.6961"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "57.1073",
            "y": "-26.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`a`}</text>
        </g>
        {
          /* q2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`q2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "175.2146",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "175.2146",
            "y": "-18.596",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q2`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M114.5374,-22.196C124.1093,-22.196 136.0369,-22.196 146.7775,-22.196"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "146.9912,-25.696 156.9912,-22.196 146.9912,-18.696 146.9912,-25.696"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-26.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`b`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M109.6432,-10.0107C117.8759,-4.0114 128.8018,1.396 139.2146,-1.396 143.4501,-2.5316 147.7026,-4.2995 151.7257,-6.3358"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "150.1623,-9.4737 160.5801,-11.4026 153.639,-3.3981 150.1623,-9.4737"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "135.7146",
            "y": "-5.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`c`}</text>
        </g>
        {
          /* q3 */
        }
        <g parentName="g" {...{
          "id": "node4",
          "className": "node"
        }}>
          <title parentName="g">{`q3`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "254.2146",
            "cy": "-22.196",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "254.2146",
            "y": "-18.596",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q3`}</text>
        </g>
        {
          /* q2&#45;&gt;q3 */
        }
        <g parentName="g" {...{
          "id": "edge4",
          "className": "edge"
        }}>
          <title parentName="g">{`q2->q3`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M193.5374,-22.196C203.1093,-22.196 215.0369,-22.196 225.7775,-22.196"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "225.9912,-25.696 235.9912,-22.196 225.9912,-18.696 225.9912,-25.696"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "214.7146",
            "y": "-26.396",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`d`}</text>
        </g>
      </g>
    </svg>
    <p>{`We'll start by composing a series of series of state equations taht list the `}<em parentName="p">{`incoming`}</em>{` transitions for each state:`}</p>
    <ul>
      <li parentName="ul"><span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`0`}</mn></msub></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`q_{0}`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.625em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03588em"
                    }
                  }}>{`q`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.3011em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.55em",
                              "marginLeft": "-0.0359em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`0`}</span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` = `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`ϵ`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`\\epsilon`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.4306em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`ϵ`}</span></span></span></span></span></li>
      <li parentName="ul"><span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`1`}</mn></msub></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`q_{1}`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.625em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03588em"
                    }
                  }}>{`q`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.3011em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.55em",
                              "marginLeft": "-0.0359em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` = `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`0`}</mn></msub><mtext parentName="mrow">{`a`}</mtext></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`q_{0}\\text{a}`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.625em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03588em"
                    }
                  }}>{`q`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.3011em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.55em",
                              "marginLeft": "-0.0359em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`0`}</span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mord text"
                }}><span parentName="span" {...{
                    "className": "mord"
                  }}>{`a`}</span></span></span></span></span></span></li>
      <li parentName="ul"><span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`q_{2}`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.625em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03588em"
                    }
                  }}>{`q`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.3011em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.55em",
                              "marginLeft": "-0.0359em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` = `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow" {...{
                      "stretchy": "false"
                    }}>{`(`}</mo><mtext parentName="mrow">{`b`}</mtext><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`∣`}</mi><mtext parentName="mrow">{`c`}</mtext><mo parentName="mrow" {...{
                      "stretchy": "false"
                    }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`q_{1}(\\text{b}|\\text{c})`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "1em",
                    "verticalAlign": "-0.25em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03588em"
                    }
                  }}>{`q`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.3011em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.55em",
                              "marginLeft": "-0.0359em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mopen"
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "mord text"
                }}><span parentName="span" {...{
                    "className": "mord"
                  }}>{`b`}</span></span><span parentName="span" {...{
                  "className": "mord"
                }}>{`∣`}</span><span parentName="span" {...{
                  "className": "mord text"
                }}><span parentName="span" {...{
                    "className": "mord"
                  }}>{`c`}</span></span><span parentName="span" {...{
                  "className": "mclose"
                }}>{`)`}</span></span></span></span></span></li>
      <li parentName="ul"><span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`3`}</mn></msub></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`q_{3}`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.625em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03588em"
                    }
                  }}>{`q`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.3011em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.55em",
                              "marginLeft": "-0.0359em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`3`}</span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` = `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`2`}</mn></msub><mtext parentName="mrow">{`d`}</mtext></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`q_{2}\\text{d}`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.8889em",
                    "verticalAlign": "-0.1944em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.03588em"
                    }
                  }}>{`q`}</span><span parentName="span" {...{
                    "className": "msupsub"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.3011em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.55em",
                              "marginLeft": "-0.0359em",
                              "marginRight": "0.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "2.7em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "sizing reset-size6 size3 mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.15em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mord text"
                }}><span parentName="span" {...{
                    "className": "mord"
                  }}>{`d`}</span></span></span></span></span></span></li>
    </ul>
    <p><strong parentName="p">{`Notes`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`0`}</mn></msub></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`q_{0}`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.625em",
                      "verticalAlign": "-0.1944em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}><span parentName="span" {...{
                      "className": "mord mathnormal",
                      "style": {
                        "marginRight": "0.03588em"
                      }
                    }}>{`q`}</span><span parentName="span" {...{
                      "className": "msupsub"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.3011em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-2.55em",
                                "marginLeft": "-0.0359em",
                                "marginRight": "0.05em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "2.7em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}>{`0`}</span></span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.15em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` refers to our start state `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`S`}</mi></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`S`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.6833em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathnormal",
                    "style": {
                      "marginRight": "0.05764em"
                    }
                  }}>{`S`}</span></span></span></span></span>{`. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`ϵ`}</mi></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`\\epsilon`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.4306em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`ϵ`}</span></span></span></span></span>{` (epsilon) corresponds to an empty string.  `}</p>
      </li>
    </ul>
    <p>{`For states with multiple incoming transitions, we'll treat them as a disjunction.`}</p>
    <p>{`To construct our regular expression, we need to subtitute in the values for the referenced states until we end up with a single regex corresponding to each terminal state:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow">{`=`}</mo><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`0`}</mn></msub><mi parentName="mrow">{`a`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`ϵ`}</mi><mtext parentName="mrow">{`a`}</mtext><mo parentName="mrow">{`=`}</mo><mtext parentName="mrow">{`a`}</mtext></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_{1} = q_{0}a = \\epsilon \\text{a} = \\text{a}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`0`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`ϵ`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`a`}</span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`a`}</span></span></span></span></span></span></p>
    <p>{`We end up with the following for `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`1`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_{1}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub" {...{
                      "mathvariant": "bold"
                    }}>{`q`}</mi><mn parentName="msub" {...{
                      "mathvariant": "bold"
                    }}>{`1`}</mn></msub><mo parentName="mrow">{`=`}</mo><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`a`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{q_{1}} = \\mathbf{a}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6389em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathbf"
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathbf mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`a`}</span></span></span></span></span></p>
    <p>{`Now let's expand the equation for `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_{2}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` by substituting the expanded form of `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`1`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_{1}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`2`}</mn></msub><mo parentName="mrow">{`=`}</mo><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`b`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`c`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mtext parentName="mrow">{`a`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`b`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`c`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_{2} = q_{1}(\\text{b}|\\text{c}) = \\text{a}(\\text{b}|\\text{c})`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`b`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`c`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`a`}</span></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`b`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`c`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span>{`  `}</p>
    <p>{`We end up with the following for `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_{2}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub" {...{
                      "mathvariant": "bold"
                    }}>{`q`}</mi><mn parentName="msub" {...{
                      "mathvariant": "bold"
                    }}>{`2`}</mn></msub><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mi parentName="mrow" {...{
                      "mathvariant": "bold"
                    }}>{`a`}</mi><mo parentName="mrow" {...{
                      "stretchy": "false"
                    }}>{`(`}</mo><mi parentName="mrow" {...{
                      "mathvariant": "bold"
                    }}>{`b`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "bold"
                    }}>{`∣`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "bold"
                    }}>{`c`}</mi><mo parentName="mrow" {...{
                      "stretchy": "false"
                    }}>{`)`}</mo></mrow></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{q_{2}} = \\mathbf{a(b|c)}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6389em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathbf"
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathbf mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathbf"
                }}>{`a`}</span><span parentName="span" {...{
                  "className": "mopen"
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "mord mathbf"
                }}>{`b∣c`}</span><span parentName="span" {...{
                  "className": "mclose"
                }}>{`)`}</span></span></span></span></span></span></p>
    <p>{`Finally, we'll expand `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`3`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_{3}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`3`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` by substituting our expanded `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_{2}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`3`}</mn></msub><mo parentName="mrow">{`=`}</mo><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`2`}</mn></msub><mtext parentName="mrow">{`d`}</mtext><mo parentName="mrow">{`=`}</mo><mtext parentName="mrow">{`a`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mtext parentName="mrow">{`b`}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{`c`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mtext parentName="mrow">{`d`}</mtext></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_{3} = q_{2}\\text{d} = \\text{a}(\\text{b}|\\text{c})\\text{d}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`3`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8889em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`d`}</span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`a`}</span></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`b`}</span></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`c`}</span></span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`d`}</span></span></span></span></span></span>{`  `}</p>
    <p>{`We end up with the following for `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`q`}</mi><mn parentName="msub">{`3`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`q_{3}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.03588em"
                  }
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "-0.0359em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`3`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub" {...{
                      "mathvariant": "bold"
                    }}>{`q`}</mi><mn parentName="msub" {...{
                      "mathvariant": "bold"
                    }}>{`3`}</mn></msub><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mtext parentName="mrow">{`a`}</mtext><mo parentName="mrow" {...{
                      "stretchy": "false"
                    }}>{`(`}</mo><mtext parentName="mrow">{`b`}</mtext><mi parentName="mrow" {...{
                      "mathvariant": "bold"
                    }}>{`∣`}</mi><mtext parentName="mrow">{`c`}</mtext><mo parentName="mrow" {...{
                      "stretchy": "false"
                    }}>{`)`}</mo><mtext parentName="mrow">{`d`}</mtext></mrow></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{q_{3}} = \\mathbf{\\text{a}(\\text{b}|\\text{c})\\text{d}}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6389em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathbf"
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathbf mtight"
                              }}>{`3`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord text"
                }}><span parentName="span" {...{
                    "className": "mord"
                  }}>{`a`}</span></span><span parentName="span" {...{
                  "className": "mopen"
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "mord text"
                }}><span parentName="span" {...{
                    "className": "mord"
                  }}>{`b`}</span></span><span parentName="span" {...{
                  "className": "mord mathbf"
                }}>{`∣`}</span><span parentName="span" {...{
                  "className": "mord text"
                }}><span parentName="span" {...{
                    "className": "mord"
                  }}>{`c`}</span></span><span parentName="span" {...{
                  "className": "mclose"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "mord text"
                }}><span parentName="span" {...{
                    "className": "mord"
                  }}>{`d`}</span></span></span></span></span></span></span></p>
    <p>{`Since `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub" {...{
                      "mathvariant": "bold"
                    }}>{`q`}</mi><mn parentName="msub" {...{
                      "mathvariant": "bold"
                    }}>{`3`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{q_{3}}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6389em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathbf"
                }}>{`q`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathbf mtight"
                              }}>{`3`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` is our terminal state, our regular expression is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`a(b|c)d`}</code>{`.  `}</p>
    <p>{`If we had more than one terminal state, our final expression would be a disjunction of the regular expression for each terminal state.`}</p>
    <p>{`This "one simple trick" is an application of `}<em parentName="p">{`Arden's theorem`}</em>{`.`}</p>
    {
      /* $R = R \cup Q\cdot P$ where $\cdot$ means concatenation. */
    }
    <h1 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next steps`}</h1>
    <p>{`You've had a taste of representing regular expressions as finite state machines.  Aside from exploring the connection to formal language theory, using a state machine to develop a regular expression can sometimes make debugging easier.`}</p>
    <h1 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h1>
    <ul>
      <li parentName="ul">{`List at least 2 strings accepted by the following state machine:`}</li>
    </ul>
    <svg {...{
      "width": "576pt",
      "height": "93pt",
      "viewBox": "0.00 0.00 576.00 93.21",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(.8197 .8197) rotate(0) translate(4 109.7158)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-109.7158 698.7346,-109.7158 698.7346,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-54.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-50.5158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "95.4446",
            "cy": "-54.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "95.4446",
            "y": "-51.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.3533,-54.7158C45.5158,-54.7158 56.8054,-54.7158 67.0534,-54.7158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "67.2231,-58.2159 77.2231,-54.7158 67.223,-51.2159 67.2231,-58.2159"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "56.7223",
            "y": "-58.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`s`}</text>
        </g>
        {
          /* q2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`q2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "174.4446",
            "cy": "-54.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "174.4446",
            "y": "-51.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q2`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M113.7674,-54.7158C123.3393,-54.7158 135.2669,-54.7158 146.0075,-54.7158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "146.2212,-58.2159 156.2212,-54.7158 146.2212,-51.2159 146.2212,-58.2159"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "134.9446",
            "y": "-58.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`n`}</text>
        </g>
        {
          /* q3 */
        }
        <g parentName="g" {...{
          "id": "node4",
          "className": "node"
        }}>
          <title parentName="g">{`q3`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "252.6592",
            "cy": "-54.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "252.6592",
            "y": "-51.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q3`}</text>
        </g>
        {
          /* q2&#45;&gt;q3 */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`q2->q3`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M192.5852,-54.7158C202.062,-54.7158 213.871,-54.7158 224.5048,-54.7158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "224.617,-58.2159 234.617,-54.7158 224.6169,-51.2159 224.617,-58.2159"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "213.5519",
            "y": "-58.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`e`}</text>
        </g>
        {
          /* q4 */
        }
        <g parentName="g" {...{
          "id": "node5",
          "className": "node"
        }}>
          <title parentName="g">{`q4`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "330.8738",
            "cy": "-54.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "330.8738",
            "y": "-51.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q4`}</text>
        </g>
        {
          /* q3&#45;&gt;q4 */
        }
        <g parentName="g" {...{
          "id": "edge4",
          "className": "edge"
        }}>
          <title parentName="g">{`q3->q4`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M270.7998,-54.7158C280.2766,-54.7158 292.0856,-54.7158 302.7194,-54.7158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "302.8316,-58.2159 312.8316,-54.7158 302.8315,-51.2159 302.8316,-58.2159"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "291.7665",
            "y": "-58.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`e`}</text>
        </g>
        {
          /* q5 */
        }
        <g parentName="g" {...{
          "id": "node6",
          "className": "node"
        }}>
          <title parentName="g">{`q5`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "409.0884",
            "cy": "-54.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "409.0884",
            "y": "-51.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q5`}</text>
        </g>
        {
          /* q4&#45;&gt;q5 */
        }
        <g parentName="g" {...{
          "id": "edge5",
          "className": "edge"
        }}>
          <title parentName="g">{`q4->q5`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M349.0144,-54.7158C358.4912,-54.7158 370.3002,-54.7158 380.934,-54.7158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "381.0462,-58.2159 391.0462,-54.7158 381.0461,-51.2159 381.0462,-58.2159"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "369.9811",
            "y": "-58.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`z`}</text>
        </g>
        {
          /* q6 */
        }
        <g parentName="g" {...{
          "id": "node7",
          "className": "node"
        }}>
          <title parentName="g">{`q6`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "491.303",
            "cy": "-83.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "491.303",
            "cy": "-83.7158",
            "rx": "22",
            "ry": "22"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "491.303",
            "y": "-80.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q6`}</text>
        </g>
        {
          /* q5&#45;&gt;q6 */
        }
        <g parentName="g" {...{
          "id": "edge6",
          "className": "edge"
        }}>
          <title parentName="g">{`q5->q6`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M426.1233,-60.7246C436.1914,-64.276 449.2342,-68.8766 461.0043,-73.0284"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "459.9039,-76.3515 470.4987,-76.3774 462.2325,-69.7501 459.9039,-76.3515"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "448.1957",
            "y": "-72.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`e`}</text>
        </g>
        {
          /* q8 */
        }
        <g parentName="g" {...{
          "id": "node9",
          "className": "node"
        }}>
          <title parentName="g">{`q8`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "491.303",
            "cy": "-25.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "491.303",
            "y": "-22.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q8`}</text>
        </g>
        {
          /* q5&#45;&gt;q8 */
        }
        <g parentName="g" {...{
          "id": "edge8",
          "className": "edge"
        }}>
          <title parentName="g">{`q5->q8`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M426.1233,-48.707C437.257,-44.7797 452.0284,-39.5693 464.6937,-35.1018"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "466.0237,-38.3441 474.2899,-31.7169 463.6951,-31.7428 466.0237,-38.3441"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "448.1957",
            "y": "-46.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`i`}</text>
        </g>
        {
          /* q7 */
        }
        <g parentName="g" {...{
          "id": "node8",
          "className": "node"
        }}>
          <title parentName="g">{`q7`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "578.303",
            "cy": "-83.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "578.303",
            "cy": "-83.7158",
            "rx": "22",
            "ry": "22"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "578.303",
            "y": "-80.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q7`}</text>
        </g>
        {
          /* q6&#45;&gt;q7 */
        }
        <g parentName="g" {...{
          "id": "edge7",
          "className": "edge"
        }}>
          <title parentName="g">{`q6->q7`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M513.7077,-83.7158C523.5253,-83.7158 535.2344,-83.7158 545.9702,-83.7158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "546.2755,-87.2159 556.2754,-83.7158 546.2754,-80.2159 546.2755,-87.2159"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "534.803",
            "y": "-87.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`d`}</text>
        </g>
        {
          /* q9 */
        }
        <g parentName="g" {...{
          "id": "node10",
          "className": "node"
        }}>
          <title parentName="g">{`q9`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "578.303",
            "cy": "-25.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "578.303",
            "y": "-22.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q9`}</text>
        </g>
        {
          /* q8&#45;&gt;q9 */
        }
        <g parentName="g" {...{
          "id": "edge9",
          "className": "edge"
        }}>
          <title parentName="g">{`q8->q9`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M509.3295,-25.7158C521.1112,-25.7158 536.7423,-25.7158 550.1449,-25.7158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "550.2997,-29.2159 560.2996,-25.7158 550.2996,-22.2159 550.2997,-29.2159"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "534.803",
            "y": "-29.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`n`}</text>
        </g>
        {
          /* q10 */
        }
        <g parentName="g" {...{
          "id": "node11",
          "className": "node"
        }}>
          <title parentName="g">{`q10`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "669.0188",
            "cy": "-25.7158",
            "rx": "21.8995",
            "ry": "21.8995"
          }}></ellipse>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "669.0188",
            "cy": "-25.7158",
            "rx": "25.9334",
            "ry": "25.9334"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "669.0188",
            "y": "-22.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q10`}</text>
        </g>
        {
          /* q9&#45;&gt;q10 */
        }
        <g parentName="g" {...{
          "id": "edge10",
          "className": "edge"
        }}>
          <title parentName="g">{`q9->q10`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M596.6607,-25.7158C607.096,-25.7158 620.5292,-25.7158 632.9826,-25.7158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "633.1258,-29.2159 643.1257,-25.7158 633.1257,-22.2159 633.1258,-29.2159"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "621.803",
            "y": "-29.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`g`}</text>
        </g>
      </g>
    </svg>
    <ul>
      <li parentName="ul">{`How many states does the following finite state machine have?  How many are terminal states?`}</li>
    </ul>
    <svg {...{
      "width": "576pt",
      "height": "93pt",
      "viewBox": "0.00 0.00 576.00 93.21",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(.8197 .8197) rotate(0) translate(4 109.7158)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-109.7158 698.7346,-109.7158 698.7346,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-54.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-50.5158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "95.4446",
            "cy": "-54.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "95.4446",
            "y": "-51.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.3533,-54.7158C45.5158,-54.7158 56.8054,-54.7158 67.0534,-54.7158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "67.2231,-58.2159 77.2231,-54.7158 67.223,-51.2159 67.2231,-58.2159"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "56.7223",
            "y": "-58.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`s`}</text>
        </g>
        {
          /* q2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`q2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "174.4446",
            "cy": "-54.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "174.4446",
            "y": "-51.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q2`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M113.7674,-54.7158C123.3393,-54.7158 135.2669,-54.7158 146.0075,-54.7158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "146.2212,-58.2159 156.2212,-54.7158 146.2212,-51.2159 146.2212,-58.2159"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "134.9446",
            "y": "-58.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`n`}</text>
        </g>
        {
          /* q3 */
        }
        <g parentName="g" {...{
          "id": "node4",
          "className": "node"
        }}>
          <title parentName="g">{`q3`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "252.6592",
            "cy": "-54.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "252.6592",
            "y": "-51.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q3`}</text>
        </g>
        {
          /* q2&#45;&gt;q3 */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`q2->q3`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M192.5852,-54.7158C202.062,-54.7158 213.871,-54.7158 224.5048,-54.7158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "224.617,-58.2159 234.617,-54.7158 224.6169,-51.2159 224.617,-58.2159"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "213.5519",
            "y": "-58.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`e`}</text>
        </g>
        {
          /* q4 */
        }
        <g parentName="g" {...{
          "id": "node5",
          "className": "node"
        }}>
          <title parentName="g">{`q4`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "330.8738",
            "cy": "-54.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "330.8738",
            "y": "-51.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q4`}</text>
        </g>
        {
          /* q3&#45;&gt;q4 */
        }
        <g parentName="g" {...{
          "id": "edge4",
          "className": "edge"
        }}>
          <title parentName="g">{`q3->q4`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M270.7998,-54.7158C280.2766,-54.7158 292.0856,-54.7158 302.7194,-54.7158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "302.8316,-58.2159 312.8316,-54.7158 302.8315,-51.2159 302.8316,-58.2159"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "291.7665",
            "y": "-58.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`e`}</text>
        </g>
        {
          /* q5 */
        }
        <g parentName="g" {...{
          "id": "node6",
          "className": "node"
        }}>
          <title parentName="g">{`q5`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "409.0884",
            "cy": "-54.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "409.0884",
            "y": "-51.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q5`}</text>
        </g>
        {
          /* q4&#45;&gt;q5 */
        }
        <g parentName="g" {...{
          "id": "edge5",
          "className": "edge"
        }}>
          <title parentName="g">{`q4->q5`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M349.0144,-54.7158C358.4912,-54.7158 370.3002,-54.7158 380.934,-54.7158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "381.0462,-58.2159 391.0462,-54.7158 381.0461,-51.2159 381.0462,-58.2159"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "369.9811",
            "y": "-58.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`z`}</text>
        </g>
        {
          /* q6 */
        }
        <g parentName="g" {...{
          "id": "node7",
          "className": "node"
        }}>
          <title parentName="g">{`q6`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "491.303",
            "cy": "-83.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "491.303",
            "cy": "-83.7158",
            "rx": "22",
            "ry": "22"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "491.303",
            "y": "-80.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q6`}</text>
        </g>
        {
          /* q5&#45;&gt;q6 */
        }
        <g parentName="g" {...{
          "id": "edge6",
          "className": "edge"
        }}>
          <title parentName="g">{`q5->q6`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M426.1233,-60.7246C436.1914,-64.276 449.2342,-68.8766 461.0043,-73.0284"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "459.9039,-76.3515 470.4987,-76.3774 462.2325,-69.7501 459.9039,-76.3515"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "448.1957",
            "y": "-72.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`e`}</text>
        </g>
        {
          /* q8 */
        }
        <g parentName="g" {...{
          "id": "node9",
          "className": "node"
        }}>
          <title parentName="g">{`q8`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "491.303",
            "cy": "-25.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "491.303",
            "y": "-22.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q8`}</text>
        </g>
        {
          /* q5&#45;&gt;q8 */
        }
        <g parentName="g" {...{
          "id": "edge8",
          "className": "edge"
        }}>
          <title parentName="g">{`q5->q8`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M426.1233,-48.707C437.257,-44.7797 452.0284,-39.5693 464.6937,-35.1018"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "466.0237,-38.3441 474.2899,-31.7169 463.6951,-31.7428 466.0237,-38.3441"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "448.1957",
            "y": "-46.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`i`}</text>
        </g>
        {
          /* q7 */
        }
        <g parentName="g" {...{
          "id": "node8",
          "className": "node"
        }}>
          <title parentName="g">{`q7`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "578.303",
            "cy": "-83.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "578.303",
            "cy": "-83.7158",
            "rx": "22",
            "ry": "22"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "578.303",
            "y": "-80.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q7`}</text>
        </g>
        {
          /* q6&#45;&gt;q7 */
        }
        <g parentName="g" {...{
          "id": "edge7",
          "className": "edge"
        }}>
          <title parentName="g">{`q6->q7`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M513.7077,-83.7158C523.5253,-83.7158 535.2344,-83.7158 545.9702,-83.7158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "546.2755,-87.2159 556.2754,-83.7158 546.2754,-80.2159 546.2755,-87.2159"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "534.803",
            "y": "-87.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`d`}</text>
        </g>
        {
          /* q9 */
        }
        <g parentName="g" {...{
          "id": "node10",
          "className": "node"
        }}>
          <title parentName="g">{`q9`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "578.303",
            "cy": "-25.7158",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "578.303",
            "y": "-22.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q9`}</text>
        </g>
        {
          /* q8&#45;&gt;q9 */
        }
        <g parentName="g" {...{
          "id": "edge9",
          "className": "edge"
        }}>
          <title parentName="g">{`q8->q9`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M509.3295,-25.7158C521.1112,-25.7158 536.7423,-25.7158 550.1449,-25.7158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "550.2997,-29.2159 560.2996,-25.7158 550.2996,-22.2159 550.2997,-29.2159"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "534.803",
            "y": "-29.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`n`}</text>
        </g>
        {
          /* q10 */
        }
        <g parentName="g" {...{
          "id": "node11",
          "className": "node"
        }}>
          <title parentName="g">{`q10`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "669.0188",
            "cy": "-25.7158",
            "rx": "21.8995",
            "ry": "21.8995"
          }}></ellipse>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "669.0188",
            "cy": "-25.7158",
            "rx": "25.9334",
            "ry": "25.9334"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "669.0188",
            "y": "-22.1158",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q10`}</text>
        </g>
        {
          /* q9&#45;&gt;q10 */
        }
        <g parentName="g" {...{
          "id": "edge10",
          "className": "edge"
        }}>
          <title parentName="g">{`q9->q10`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M596.6607,-25.7158C607.096,-25.7158 620.5292,-25.7158 632.9826,-25.7158"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "633.1258,-29.2159 643.1257,-25.7158 633.1257,-22.2159 633.1258,-29.2159"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "621.803",
            "y": "-29.9158",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`g`}</text>
        </g>
      </g>
    </svg>
    <ul>
      <li parentName="ul">{`Write an equivalent regex that accepts the same strings as the following state machine:`}</li>
    </ul>
    <svg {...{
      "width": "372pt",
      "height": "87pt",
      "viewBox": "0.00 0.00 372.09 86.80",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlnsXLink": "http://www.w3.org/1999/xlink",
      "style": {
        "maxWidth": "100%",
        "height": "auto"
      }
    }}>
      <g parentName="svg" {...{
        "id": "graph0",
        "className": "graph",
        "transform": "scale(1 1) rotate(0) translate(4 82.8)"
      }}>
        <title parentName="g">{`finite_state_machine`}</title>
        <polygon parentName="g" {...{
          "fill": "#ffffff",
          "stroke": "transparent",
          "points": "-4,4 -4,-82.8 368.0884,-82.8 368.0884,4 -4,4"
        }}></polygon>
        {
          /* S */
        }
        <g parentName="g" {...{
          "id": "node1",
          "className": "node"
        }}>
          <title parentName="g">{`S`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "18",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "18",
            "y": "-17.8",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`S`}</text>
        </g>
        {
          /* q1 */
        }
        <g parentName="g" {...{
          "id": "node2",
          "className": "node"
        }}>
          <title parentName="g">{`q1`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "100.1066",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "100.1066",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q1`}</text>
        </g>
        {
          /* S&#45;&gt;q1 */
        }
        <g parentName="g" {...{
          "id": "edge1",
          "className": "edge"
        }}>
          <title parentName="g">{`S->q1`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M36.2221,-22C46.6396,-22 59.9329,-22 71.693,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "71.9355,-25.5001 81.9354,-22 71.9354,-18.5001 71.9355,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "59.0533",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`A`}</text>
        </g>
        {
          /* q2 */
        }
        <g parentName="g" {...{
          "id": "node3",
          "className": "node"
        }}>
          <title parentName="g">{`q2`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "176.7672",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "176.7672",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q2`}</text>
        </g>
        {
          /* q1&#45;&gt;q2 */
        }
        <g parentName="g" {...{
          "id": "edge2",
          "className": "edge"
        }}>
          <title parentName="g">{`q1->q2`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M118.2741,-22C127.3439,-22 138.5192,-22 148.6634,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "148.7301,-25.5001 158.7301,-22 148.7301,-18.5001 148.7301,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "138.4369",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`r`}</text>
        </g>
        {
          /* q3 */
        }
        <g parentName="g" {...{
          "id": "node4",
          "className": "node"
        }}>
          <title parentName="g">{`q3`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "257.4278",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "257.4278",
            "cy": "-22",
            "rx": "22",
            "ry": "22"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "257.4278",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q3`}</text>
        </g>
        {
          /* q2&#45;&gt;q3 */
        }
        <g parentName="g" {...{
          "id": "edge3",
          "className": "edge"
        }}>
          <title parentName="g">{`q2->q3`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M195.0703,-22C203.9017,-22 214.7658,-22 224.9379,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "225.1735,-25.5001 235.1734,-22 225.1734,-18.5001 225.1735,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "215.0975",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`r`}</text>
        </g>
        {
          /* q3&#45;&gt;q3 */
        }
        <g parentName="g" {...{
          "id": "edge4",
          "className": "edge"
        }}>
          <title parentName="g">{`q3->q3`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M249.7451,-42.9908C248.7966,-53.0872 251.3575,-62 257.4278,-62 261.3166,-62 263.7651,-58.3422 264.7734,-53.0587"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "268.2738,-53.1023 265.1105,-42.9908 261.2777,-52.868 268.2738,-53.1023"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "257.4278",
            "y": "-66.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`r`}</text>
        </g>
        {
          /* q4 */
        }
        <g parentName="g" {...{
          "id": "node5",
          "className": "node"
        }}>
          <title parentName="g">{`q4`}</title>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "342.0884",
            "cy": "-22",
            "rx": "18",
            "ry": "18"
          }}></ellipse>
          <ellipse parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "cx": "342.0884",
            "cy": "-22",
            "rx": "22",
            "ry": "22"
          }}></ellipse>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "342.0884",
            "y": "-18.4",
            "fontFamily": "Times,serif",
            "fontSize": "12.00",
            "fill": "#000000"
          }}>{`q4`}</text>
        </g>
        {
          /* q3&#45;&gt;q4 */
        }
        <g parentName="g" {...{
          "id": "edge5",
          "className": "edge"
        }}>
          <title parentName="g">{`q3->q4`}</title>
          <path parentName="g" {...{
            "fill": "none",
            "stroke": "#000000",
            "d": "M279.6714,-22C288.907,-22 299.8033,-22 309.8853,-22"
          }}></path>
          <polygon parentName="g" {...{
            "fill": "#000000",
            "stroke": "#000000",
            "points": "309.9934,-25.5001 319.9934,-22 309.9933,-18.5001 309.9934,-25.5001"
          }}></polygon>
          <text parentName="g" {...{
            "textAnchor": "middle",
            "x": "299.7581",
            "y": "-26.2",
            "fontFamily": "Times,serif",
            "fontSize": "14.00",
            "fill": "#000000"
          }}>{`!`}</text>
        </g>
      </g>
    </svg>
    <h2 {...{
      "id": "resources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#resources",
        "aria-label": "resources permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.debuggex.com/",
          "target": "_self",
          "rel": "nofollow"
        }}>{`https://www.debuggex.com/`}</a>
        <ul parentName="li">
          <li parentName="ul">{`A regex debugger that visualizes expressions as state machines`}</li>
        </ul>
      </li>
    </ul>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`In formal language theory, a `}<strong parentName="li">{`language`}</strong>{` defines a set of strings.  Each string is a sequence of symbols.`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`Also known as finite state automata (FSA).`}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}>{`"Sigma".`}<a parentName="li" {...{
            "href": "#fnref-3",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-4"
        }}>{`"delta".`}<a parentName="li" {...{
            "href": "#fnref-4",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-5"
        }}>{`Also known as an `}<strong parentName="li">{`accepting`}</strong>{` state.`}<a parentName="li" {...{
            "href": "#fnref-5",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      